/* eslint-disable */
// https://github.com/emotion-js/emotion/issues/1306
/** @jsx jsx */
import { jsx } from '@emotion/core'

import { useState, useEffect } from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import { css } from "@emotion/core"
import { Default } from "../utils/responsive"
import 'minireset.css'
import imgLogo from '../images/header-logo.svg'
import imgHeaderLeft from '../images/header-left.svg'
import imgHeaderRight from '../images/header-right.svg'
import imgHeaderMenu from '../images/burger-menu.svg'
import imgHeaderMenuClose from '../images/close.svg'

function throttle (callback, limit) {
  var wait = false;                  // Initially, we're not waiting
  return function () {               // We return a throttled function
      if (!wait) {                   // If we're not waiting
          callback.call();           // Execute users function
          wait = true;               // Prevent future invocations
          setTimeout(function () {   // After a period of time
              wait = false;          // And allow future invocations
          }, limit);
      }
  }
}

export const query = graphql`
  query {
    prismic {
      menu(uid: "main", lang: "en-us") {
        menu_links {
          label
          link {
            ... on PRISMIC_Page {
              __typename
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__ExternalLink {
              __typename
              url
            }
            ... on PRISMIC_Category {
              __typename
              name
              _meta {
                uid
              }
            }
          }
        }
      }
    }
  }
`

const Header = ({ siteTitle }) => {

  const [stickyHeader, setStickyHeader] = useState(false)
  const [mobileHeader, setMobileHeader] = useState(false)

  useEffect(() => {
    if(window.pageYOffset || document.documentElement.scrollTop > 0){
      setStickyHeader(true)
    }
    window.addEventListener("scroll", throttle(scrollHandler, 100), { passive: true });
    return () => {
      window.removeEventListener('scroll', scrollHandler)
    };
  }, []);

  function scrollHandler () {
    let st = window.pageYOffset || document.documentElement.scrollTop || 0
    if(st >= 243) {
      setStickyHeader(true)
    } else {
      setStickyHeader(false)
    }
  }

  function toggleMobileHeader () {
    setMobileHeader(!mobileHeader)
  }

  const linkStyle = css`
  display: inline-block;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  line-height: 3.67;
  letter-spacing: 2.4px;
  color: #000;
  text-decoration: none;
  padding: 0 1.25em;
  font-family: 'Libre Franklin', 'sans-serif';
  &:hover{
    text-decoration: underline;
  }
  /*
  &:last-child::before{
    content: "|";
    display: inline-block;
    margin-left: -1.25em;
    margin-right: 1.25em;
  }
  */
`

  const stickyHeaderStyle = () => {
    if(stickyHeader) {
      return `height: 80px;`
    }
  }

  const stickyLogoStyle = () => {
    if(stickyHeader) {
      return `position: fixed; left: 2.5em; top: 8px; height: 80px;
      
      img{
        height: 80px;
        width: auto;
        margin: 0;
      }
      `
    }
  }

  const stickyNavStyle = () => {
    if(stickyHeader) {
      return `position: absolute; right: 1.25em; bottom: 0; height: 100%; align-items: center; justify-content: end; width: auto;
      
      @media (max-width: 999px) {
        display: none;
      } 
      `
    }
  }

  const mobileNavStyle = () => {
    if(mobileHeader) {
      return `
        display: flex;
        flex-direction: column; align-items:center;justify-content: center;
        background: #fff;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      `
    }
  }

  return (<StaticQuery query={query} render={({ prismic: { menu: { menu_links } } }) => { 
    return (
    <div>
  <header id="header" css={css`
  background-color: #fbfbfb;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  /*transition: height 150ms ease-in-out;*/
  height: 253px;
  z-index: 100;
  ${stickyHeaderStyle()}
  @media (max-width: 768px) {
    height: 80px;
  }

  `}>
      <div css={css`
      @media (min-width: 768px) {
        position: relative;display:flex;align-items:center;justify-content: center; height:100%; max-width:1170px; margin: 0 auto;
      }
      `}>
        <h1 css={css`${stickyLogoStyle()}
        

  @media (max-width: 768px) {
        position: fixed; left: 2.5em; top: 8px; height: 80px;
        left: 1em;
      
      img{
        height: 80px;
        width: auto;
        margin: 0;
      }
  }

        `}><Link to="/"><img css={css`width: 342px;height: 203px; margin-bottom: 35px;`} src={imgLogo} alt="Feldmann trommelt" /></Link></h1>
        { stickyHeader ? null :
          <img css={css`position:absolute;left:116px;margin-bottom:35px;
          @media (max-width: 1009px) {
            display: none;
          }          
          `} src={imgHeaderLeft} alt="Opinion. Opinion Opinion?" />
        }
        { stickyHeader ? null :
          <img css={css`position:absolute;right:0;margin-bottom:35px;
          @media (max-width: 1009px) {
            display: none;
          } 
          `} src={imgHeaderRight} alt="Nothing on this page claims objectivity. Everything is true in my opinion." />
        }
      </div>
      <img css={css`
      
      @media (min-width: 1000px) { display: none; }
      position: absolute; top: 8px; right: 0; padding: 16px 1em; cursor: pointer; z-index: 101;
      `} src={mobileHeader ? imgHeaderMenuClose : imgHeaderMenu} alt="Menu" 
      onClick={ () => toggleMobileHeader() }
      />
      <nav css={css`width: 100%; 
      @media (min-width: 768px) {
        position: absolute; bottom: 15px; display: flex; justify-content: center;
        ${stickyNavStyle()}
      }
      @media (max-width: 800px) {
        display: none;
        ${mobileNavStyle()}
      }
      `}>
        {menu_links && menu_links.map((link, index) => {
          const type = link.link.__typename
          const label = link.label[0].text || link.link.name || null
          if (type === `PRISMIC__ExternalLink`) {
            return <a key={index} href={link.link.url} target="_blank" css={linkStyle}>{label}</a>
          }
          if (type === `PRISMIC_Category`) {
            return <Link key={index} activeStyle={{ color: "red" }} to={`/category/${link.link._meta.uid}`} css={linkStyle}>{label}</Link>
          }
          if (type === `PRISMIC_Page`) {
            return <Link key={index} activeStyle={{ color: "red" }} to={`${link.link._meta.uid}`} css={linkStyle}>{label}</Link>
          }
        })}
      </nav>
  </header>
  <div css={css`height: 253px;
      @media (max-width: 800px) { height: 80px; }`}></div>
  </div>
)}} />)
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
