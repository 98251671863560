import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { RichText } from "prismic-reactjs/src"
import SEO from '../components/seo'
import Layout from "../components/layout"
import { css } from "@emotion/core"
import 'minireset.css'

export const query = graphql`
  query PageQuery($uid: String!) {
    prismic {
      page(uid: $uid lang: "en-us") {
        title
        image
        imageSharp{
          childImageSharp {
            fluid(maxWidth: 1920, maxHeight: 1080) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        body
      }
    }
  }
`

const Page = ({ data }) => {
  if (data.prismic.page === null || data.prismic.page === undefined) { return null }
  const { title, image,  body } = data.prismic.page
  // console.log(data.prismic.page.imageSharp.fluid)
  const gatsbyImage = data.prismic.page.imageSharp
  return (
    <>
      <SEO title={RichText.asText(title)} />
      <Layout>
      { 
      // Check if imageSharp exists
      (gatsbyImage === undefined || gatsbyImage === null) ? 
      // Use normalge image for previews
      image === null ? null : <img src={image.url} />
      // Gatsby Image for production and published posts
      : <Img fluid={data.prismic.page.imageSharp.childImageSharp.fluid} /> 
      }



        {/* <h1>{RichText.asText(title)}</h1>
        <RichText render={body} /> */}

<div  css={css` @media (max-width: 768px) { padding: 0 20px; } margin: 0 auto; max-width: 860px;`}>

      <h1 css={css`
  width: 100%;
  font-family: 'Cormorant', Serif;
  font-size: 60px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.03;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  margin-bottom: 50px;
  margin-top: 50px;
 
  @media (max-width: 768px) {
    font-size: 30px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  
  `}>{RichText.asText(title)}</h1>
      <div css={css`
  font-family: 'Cormorant', Serif;
  font-size: 25px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.52;
  letter-spacing: 0.57px;
  color: #000000;
  .embed{
    margin-bottom: 50px;
    &.embed-instagram .instagram-media{
      margin: 0 auto !important;
    }
    &.embed-youtube {
      position: relative;
      padding-bottom: 56.25%;
      padding-top: 30px;
      height: 0;
      overflow: hidden;

      iframe{
        position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
      }
    }
  }
  .gatsby-image-wrapper{
    margin: 0 auto;
    display: block;
    margin-bottom: 50px;
  }
  p{
    margin-bottom: 50px;
    &.intro{
        font-family: ChronicleDisplay-Roman, Serif;
        font-size: 35px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.23;
        letter-spacing: normal;
        color: #000000;
    }
  }

  a{
    color: #f00;
    &:hover{
      color: #000;
    }
  }


  @media (max-width: 768px) {
    font-size: 18px;
    p{
      margin-bottom: 20px;
    }
  }
      `}><RichText render={body} /></div>
      </div>


      </Layout>
    </>
  )
}

Page.query = graphql`
  query PageQuery($uid: String!) {
    prismic {
      page(uid: $uid lang: "en-us") {
        title
        image
        body
      }
    }
  }
`

export default Page