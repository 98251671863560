// https://github.com/emotion-js/emotion/issues/1306
/** @jsx jsx */
import { jsx } from '@emotion/core'

import { Link, StaticQuery, graphql } from "gatsby"
import { css } from "@emotion/core"
import 'minireset.css'
import imgFooter from '../images/footer-menu.png'

export const query = graphql`
  query {
    prismic {
      menu(uid: "main", lang: "en-us") {
        menu_links {
          label
          link {
            ... on PRISMIC_Page {
              __typename
              title
              _meta {
                uid
              }
            }
            ... on PRISMIC__ExternalLink {
              __typename
              url
            }
            ... on PRISMIC_Category {
              __typename
              name
              _meta {
                uid
              }
            }
          }
        }
      }
    }
  }
`

const linkStyle = css`
display: inline-block;
text-transform: uppercase;
font-size: 12px;
line-height: 3.67;
letter-spacing: 2.4px;
font-weight: bold;
color: #000;
text-decoration: none;
padding: 0 1.25em;
font-family: 'Libre Franklin', 'sans-serif';
&:hover{
  text-decoration: underline;
}
`

const legalLinks = [
  {
    to: '/privacy-policy',
    name: 'Privacy Policy'
  },
  {
    to: '/imprint',
    name: 'Imprint'
  },
  {
    to: '/contact',
    name: 'Contact'
  }
]

const Footer = () => {

  return (<StaticQuery query={query} render={({ prismic: { menu: { menu_links } } }) => { 
    return (
  <footer css={css`text-align:center;padding-top: 40px;`}>
    <img css={css`margin-bottom: -180px;`} src={imgFooter} alt="Feldmann trommelt" />
    <div css={css`text-align:center;display: block; max-width: 520px; margin: 0 auto; margin-bottom: 30px;`}><span css={css`
  font-family: 'Cormorant', Serif;
  font-size: 60px;
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ff0000;
  padding-bottom: 10px;
  display: block;
`}>Stay in touch.</span>
  
  <div css={css`min-height:41px; background-color: #000000; display: flex; align-items:center;justify-content: center; 
  
  
  @media (max-width: 768px) {
      display: block;
  }

  `}>
  <form action="https://trophymagazines.us5.list-manage.com/subscribe/post?u=e52438aa09ab36602c51e99c7&amp;id=38a77fa094" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
    <input required type="email" name="EMAIL" placeholder="Subscribe with your mailaddress" 
    css={css`
    min-width: 400px;
    border: 0;
    background: #000;
    font-family: 'Cormorant', Serif;
    font-size: 24px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    ::placeholder{
      opacity: 1
      color: #cbcbcb;
    }
    @media (max-width: 768px) {
      min-width: auto;
      max-width: 100%;
    }
    `}
    
    /><input type="text" name="b_e52438aa09ab36602c51e99c7_38a77fa094" tabindex="-1" value="" css={css`display: none;`} />
    {/* <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"> */}
    <button type="submit" value="Subscribe" name="subscribe" css={css`
  font-family: 'Cormorant', Serif;
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
    border: 0;
    background: #000;
    `}>Send</button>
    </form>
    </div>
  
</div>
    <nav>{menu_links && menu_links.map((link, index) => {
          const type = link.link.__typename
          const label = link.label[0].text || link.link.name || null
          if (type === `PRISMIC__ExternalLink`) {
            return <a key={index} href={link.link.url} target="_blank" css={linkStyle}>{label}</a>
          }
          if (type === `PRISMIC_Category`) {
            return <Link key={index} activeStyle={{ color: "red" }} to={`/category/${link.link._meta.uid}`} css={linkStyle}>{label}</Link>
          }
          if (type === `PRISMIC_Page`) {
            return <Link key={index} activeStyle={{ color: "red" }} to={`${link.link._meta.uid}`} css={linkStyle}>{label}</Link>
          }
        })}</nav>

<nav>{legalLinks.map((link, index) => <Link key={index} to={link.to} css={css`
  display: inline-block;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 3.67;
  letter-spacing: 2.4px;
  font-weight: bold;
  color: #000;
  text-decoration: none;
  padding: 0 1.25em;
  font-family: 'Libre Franklin', 'sans-serif';
  &:hover{
    text-decoration: underline;
  }
  &::before{
    content: "|";
    display: inline-block;
    margin-left: -1.25em;
    margin-right: 1.25em;
  }
  &:first-of-type::before{
    content: "";
  }
`}>{link.name}</Link>)}</nav>

    <small
    
    css={css`
      padding-top: 10px;
      display: block;
      font-family: 'Cormorant', Serif;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 2.15;
      letter-spacing: normal;
      color: #000000;
      margin-bottom: 100px;
    `}
    
    >&copy; Lisa Feldmann {new Date().getFullYear()} All rights reserved</small>
  </footer>
  )}} />)
}

export default Footer
